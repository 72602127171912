/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #f92044;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  border-bottom: solid 4px #001fb9;
}

/* Fancy blur effect */
/* #nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #558d28, 0 0 5px #558d28;
	opacity: 1.0;

	-webkit-transform: rotate(3deg) translate(0px, -4px, 0);
	-ms-transform: rotate(3deg) translate(0px, -4px, 0);
	transform: rotate(3deg) translate(0px, -4px, 0);
} */

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  /* background: rgba(255, 255, 255, 0.75); */
  /* border-radius: 0.5rem; */
  /* padding: 0.5rem; */
  display: block;
  position: fixed;
  z-index: 1031;
  /* top: 50%;
	left: 50%;
  transform: translate(-50%, -50%); */
  bottom: 1rem;
  right: 1rem;
}

#nprogress .spinner-icon {
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;

  border: solid 3px transparent;
  border-top-color: #f92044;
  border-left-color: #f92044;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 600ms ease-in-out infinite;
  animation: nprogress-spinner 600ms ease-in-out infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    border-top-color: #f92044;
    border-left-color: #f92044;
  }
  50% {
    border-top-color: #001fb9;
    border-left-color: #001fb9;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border-top-color: #f92044;
    border-left-color: #f92044;
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
    border-top-color: #f92044;
    border-left-color: #f92044;
  }
  50% {
    border-top-color: #001fb9;
    border-left-color: #001fb9;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: #f92044;
    border-left-color: #f92044;
  }
}
