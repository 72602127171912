body {
  font-size: 16px;
  line-height: 22px;
  font-family: "BrandonText-Regular";
  color: #3c3c3c;
  letter-spacing: 0.1px;
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open main {
  z-index: 10000 !important;
}

body.modal-open .bp-sidebar {
  z-index: -1 !important;
}

body.modal-open .layout::before {
  z-index: 1 !important;
}

body.sub-menu-active {
  position: relative;
  overflow: hidden;
}

body.sub-menu-active:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9;
}

@media screen and (max-width: 767px) {
  body.sub-menu-active:after {
    display: none;
  }

  body.modal-open,
  html.modal-open {
    height: 100%;
    overflow: hidden;
  }

  body.modal-open main {
    z-index: 1 !important;
  }

  body.modal-open .bp-sidebar {
    z-index: 3 !important;
  }

  body.filter-open .bp-sidebar {
    display: none;
  }

  body.modal-open .bp-hero > div {
    max-height: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  body.menu-open {
    height: 100vh;
    overflow: hidden;
  }
}

strong {
  font-family: "BrandonText-Bold";
  font-weight: normal;
}

*,
:after,
:before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "BrandonText-Bold";
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
}

h2 {
  font-size: 30px;
  line-height: 38px;
  color: #001fb9;
}

@media (max-width: 1199px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

h3 {
  font-size: 30px;
  line-height: 38px;
  color: #001fb9;
}

@media (max-width: 767px) {
  h3 {
    font-size: 22px;
  }
}

h4 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #001fb9;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  color: #001fb9;
  transition: all 0.25s ease-in;
  text-decoration: none;
}

a:hover {
  color: #f92044;
  text-decoration: none;
}

@media (max-width: 1199px) {
  .bp-logo {
    display: flex;
    align-items: center;
    flex: 1;
  }
}

.bp-logo img {
  height: 50px;
}

@media (max-width: 1199px) {
  .bp-logo img {
    height: 35px;
  }
}

@media (max-width: 767px) {
  .bp-logo > a:first-child {
    flex: 0 0 150px;
  }

  .bp-logo img {
    width: 150px;
    height: auto;
  }

  .hidden-mb {
    display: none;
  }
}

.bp-office-name {
  border-top: 1px solid #001fb9;
  font-size: 22px;
  line-height: 30px;
  display: block;
  font-family: "BrandonText-Medium";
  position: relative;
  text-transform: uppercase;
  color: #f92044;
  letter-spacing: 2px;
  padding-top: 16px;
  margin-top: 10px;
}

@media (max-width: 1199px) {
  .bp-office-name {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.5px;
    margin-left: 16px;
    padding-left: 18px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  .bp-office-name::before {
    position: absolute;
    left: 0;
    top: -1px;
    content: "|";
    color: #001fb9;
  }
}

@media (max-width: 767px) {
  .bp-office-name {
    font-family: "BrandonText-Bold";
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.5px;
    padding-left: 12px;
    margin-left: 10px;
    flex: 1;
  }

  .bp-office-name::before {
    top: 50%;
    margin-top: -11px;
    content: "";
    height: 22px;
    width: 1px;
    background-color: #001fb9;
  }
}

.bp-sidebar-auth-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.bp-sidebar-auth-link a {
  text-decoration: none;
  color: #001fb9;
  margin: 0 15px;
  position: relative;
}

.bp-sidebar-auth-link a:hover {
  color: #f92044;
}

.bp-sidebar-auth-link a:first-child {
  margin-left: 0;
}

.bp-sidebar-auth-link a:last-child:after {
  display: none;
}

.bp-sidebar-auth-link a:after {
  font-size: 16px;
  content: "|";
  position: absolute;
  right: -15px;
}

.bp-menu-item-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: "BrandonText-Bold";
  font-size: 18px;
  line-height: 144%;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.bp-menu-item-label svg {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info svg {
  margin-right: 16px;
}

.mobile-burger {
  position: relative;
  cursor: pointer;
  display: none;
  transition: all 0.25s;
}
@media (max-width: 1199px) {
  .mobile-burger {
    display: block;
  }
}
.mobile-burger span {
  display: block;
  background: #001fb9;
  transition: all 0.25s;
  height: 1px;
  width: 24px;
  margin-bottom: 6px;
}
.mobile-burger span:last-child {
  margin-bottom: 0;
}
.mobile-burger.close span {
  height: 2px;
}
.mobile-burger.close span:first-child {
  transform: translate(0px, 8px) rotate(135deg);
}
.mobile-burger.close span:nth-child(2) {
  left: -20px;
  opacity: 0;
}
.mobile-burger.close span:last-child {
  transform: translate(0px, -8px) rotate(-135deg);
}

/*
  Menu
*/
.bp-menu {
  list-style: none;
  margin-bottom: 65px;
  flex: 1;
  max-width: 175px;
}

.bp-menu.with-office .bp-sub-menu {
  padding-top: 190px;
}

.bp-menu-item {
  margin-bottom: 20px;
  font-size: 18px;
  color: #001fb9;
}

.bp-menu-item:hover {
  transition: all 0.25s;
  color: #f92044;
}

.bp-menu-item svg {
  transition: all 0.2s ease-in;
}

.bp-menu-item.active {
  color: #f92044;
}

@media (max-width: 1199px) {
  .bp-menu-item.active svg {
    transform: rotate(180deg);
    color: #f92044;
  }
}

.bp-sub-menu {
  list-style: none;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 352px;
  padding: 135px 42px 0;
  background-color: #ede8de;
}

.bp-sub-menu.active {
  display: block;
}

@media (max-width: 1199px) {
  .bp-sub-menu {
    padding: 32px 0 24px;
    position: relative;
    left: auto !important;
    top: auto !important;
    max-width: 100%;
    height: auto !important;
  }
}

.bp-sub-menu-item {
  margin-bottom: 20px;
}

.bp-sub-menu-item:last-child {
  margin-bottom: 0;
}

.bp-sub-menu-item a {
  text-decoration: none;
  text-transform: none;
  font-family: "BrandonText-Medium";
  display: inline-block;
  line-height: 144%;
  letter-spacing: 0.1px;
}

@media (max-width: 1199px) {
  .bp-sub-menu-item {
    margin-bottom: 10px;
  }
}

.bp-main-menu {
  list-style: none;
  margin-bottom: 65px;
}

.bp-main-menu-item {
  margin-bottom: 8px;
}

.bp-main-menu-item a {
  text-decoration: none;
  color: #0a1965;
  font-size: 16px;
}

.bp-main-menu-item a:hover {
  color: #f92044;
}

.bp-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
}

.bp-nav-header.with-office {
  margin-bottom: 55px;
}

@media (max-width: 1199px) {
  .bp-nav-header {
    margin-bottom: 0;
  }
}

.bp-sidebar {
  position: sticky;
  z-index: 99;
  background-color: #f5f2eb;
  width: 352px;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  padding: 24px 28px 38px;
  flex: 0 0 352px;
}

.bp-sidebar::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px;
}
.bp-sidebar::-webkit-scrollbar-track {
  background: rgba(189, 189, 189, 0.2);
}
.bp-sidebar::-webkit-scrollbar-thumb {
  background: rgba(189, 189, 189, 0.2);
}

.bp-sidebar ul {
  list-style: none;
}

.bp-sidebar .close-subMenu-btn {
  display: none;
}

.bp-sidebar .close-menu-btn {
  position: fixed;
  top: 30px;
  cursor: pointer;
  z-index: 10000;
}

.bp-sidebar button {
  margin-bottom: 24px;
}

@media (max-width: 1199px) {
  .bp-sidebar {
    border-right: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    height: 62px;
  }

  .bp-sidebar.sub-menu-active {
    background-color: #ede8de;
  }

  .bp-sidebar .close-menu-btn {
    display: none;
  }

  .bp-sidebar .bp-nav-header {
    height: 62px;
    padding: 16px 20px;
    background-color: #f5f2eb;
    width: 100%;
    position: fixed;
    z-index: 99999;
  }

  .bp-sidebar .bp-sidebar-auth-link,
  .bp-sidebar .bp-menu,
  .bp-sidebar .bp-main-menu {
    display: none;
  }

  .bp-sidebar .bp-main-menu {
    padding-left: 28px;
  }

  .bp-menu-item-label {
    padding-right: 6px;
  }

  .bp-sidebar .last-main-menu-item {
    display: none;
  }

  .bp-sidebar .close-subMenu-btn {
    display: block;
    position: fixed;
    left: 20px;
    top: 94px;
    cursor: pointer;
    z-index: 10000;
  }

  .bp-sidebar button {
    margin-bottom: 0;
  }
}

.bp-sidebar.active {
  display: block;
  border-bottom: none;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9999;
  height: 100vh;
}

.bp-sidebar.active .last-main-menu-item {
  display: inline-block;
  margin-left: 28px;
  margin-bottom: 24px;
}

.bp-sidebar.active .bp-sidebar-auth-link {
  display: block;
  bottom: unset;
  left: unset;
  margin-left: 28px;
  margin-bottom: 38px;
}

.bp-sidebar.active .bp-menu {
  display: block;
  position: relative;
  padding: 102px 28px 0;
  margin-bottom: 68px;
  max-width: 100%;
}

.bp-sidebar.active .bp-main-menu {
  display: block;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  z-index: 1;
  opacity: 0;
  transition: 0.5s all;
}

.slick-slider .slick-prev {
  left: -30px;
}

.slick-slider .slick-next {
  right: -10px;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  color: #4c96ff;
  font-size: 30px;
}

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  opacity: 1;
}

@media (min-width: 768px) {
  .visible-mobile {
    display: none;
  }
}

.main-select-container {
  min-width: 140px;
}

.main-select-container .react-select__control {
  border-radius: 4px;
  box-shadow: none;
  border-color: #c9c9cc;
}

.main-select-container .react-select__input-container {
  margin: 0 2px;
  padding: 0;
}

.main-select-container .react-select__menu-list {
  padding-top: 0;
}

.main-select-container .react-select__single-value {
  color: #0a1965;
  font-family: "BrandonText-Regular";
}

.main-select-container .react-select__value-container {
  padding: 7px 0 7px 16px;
  min-height: 40px;
}

.main-select-container .react-select__control--menu-is-open {
  border-radius: 4px 4px 0 0;
  border-color: #001fb9 !important;
  border-bottom-color: transparent !important;
  outline: none;
  box-shadow: none;
}

.main-select-container .react-select__clear-indicator.react-select__indicator {
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.main-select-container .react-select__control--menu-is-open .react-select__indicator.react-select__dropdown-indicator {
  transform: rotate(-180deg);
}

.main-select-container .react-select__menu,
.react-select__menu-portal .react-select__menu {
  margin-top: -1px;
  padding-top: 1px;
  border: 1px solid #001fb9;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: none;
}

.main-select-container .react-select__option,
.react-select__menu-portal .react-select__option  {
  padding: 10px 16px;
  color: #0a1965;
  font-family: "BrandonText-Regular";
}

.main-select-container .react-select__option:hover,
.react-select__menu-portal .react-select__option:hover {
  background-color: #001fb9;
  color: white;
}

.main-select-container .react-select__option--is-focused,
.react-select__menu-portal .react-select__option--is-focused {
  background-color: #001fb9;
  color: white;
}

.main-select-container .react-select__indicator {
  transition: transform 0.25s;
  padding: 2px 16px;
}

.main-select-container .react-select__indicator svg {
  color: #001fb9;
}

.main-select-container .react-select__option--is-selected,
.react-select__menu-portal .react-select__option--is-selected  {
  background-color: #001fb9;
  color: white;
}

.c-toastify {
  background-color: #f5f2eb;
  color: #001fb9;
  border-radius: 0;
}

.c-toastify.Toastify__toast--error {
  color: #f92044;
}

.c-toastify.Toastify__toast--error .Toastify__progress-bar {
  background-color: #f92044;
}

.c-toastify.Toastify__toast--success .Toastify__progress-bar {
  background-color: #001fb9;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

.react-tooltip {
  z-index: 99;
  max-width: 280px;
}
