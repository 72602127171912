@font-face {
  font-family: "BrandonText-Bold";
  src: url("/fonts/brandontext-bold.eot");
  src:
    url("/fonts/brandontext-bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/brandontext-bold.woff2") format("woff2"),
    url("/fonts/brandontext-bold.woff") format("woff"),
    url("/fonts/brandontext-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BrandonText-Medium";
  src: url("/fonts/brandontext-medium.eot");
  src:
    url("/fonts/brandontext-medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/brandontext-medium.woff2") format("woff2"),
    url("/fonts/brandontext-medium.woff") format("woff"),
    url("/fonts/brandontext-medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BrandonText-Regular";
  src: url("/fonts/brandontext-regular.eot");
  src:
    url("/fonts/brandontext-regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/brandontext-regular.woff2") format("woff2"),
    url("/fonts/brandontext-regular.woff") format("woff"),
    url("/fonts/brandontext-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
